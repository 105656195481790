import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import PropTypes from 'prop-types'
import Hero from '../components/Hero'
import JobView from '../components/JobView'
import Content, { HTMLContent } from '../components/Content'
import { graphql } from 'gatsby'
import { withIntl } from '../i18n'
import axios from 'axios'
// import mockJobs from '../krm';
/* eslint-disable */

export const StillingerPageTemplate = ({
  title,
  content,
  contentComponent,
  locale,
}) => {
  const [jobs, setJobs] = useState(undefined)
  const PageContent = contentComponent || Content
  useEffect(() => {
    async function fetchJobPositions() {
      const response = await axios('/.netlify/functions/getJobPositions')
      if (
        (Object.entries(response).length === 0 &&
          response.constructor === Object) ||
        !response.data ||
        !response.data.data ||
        !response.data.data.data
      )
        return
      setJobs(response.data.data.data)
    }
    fetchJobPositions()
  }, [])

  return (
    <div>
      <Hero title={title} />
      <section id="jobs-content" className="section page-content">
        <div className="container">
          <PageContent content={content} />
        </div>
      </section>
      <Jobs jobs={jobs} />
    </div>
  )
}

const Jobs = ({ jobs }) => (
  <>
    {!jobs ||
    (Object.entries(jobs).length === 0 && jobs.constructor === Object) ? (
      <></>
    ) : (
      <section id="jobs" className="section">
        <div className="jobs-grid">
          {Object.keys(jobs).map(jobKey => {
            let job = jobs[jobKey]
            return (
              <JobView
                key={job.jobPostId}
                companyLogo={job.logo}
                workplace={job.workplace}
                jobTitle={job.title}
                callToActionUrl={job.applyUrl}
                jobSubtitle={job.name}
                deadline={job.deadline}
              />
            )
          })}
        </div>
      </section>
    )}
  </>
)

StillingerPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  seoDescription: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const StillingerPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout
      seoDescription={post.frontmatter.seoDescription}
      seoTitle={post.frontmatter.seoTitle}
    >
      <StillingerPageTemplate
        contentComponent={HTMLContent}
        content={post.html}
        title={post.frontmatter.title}
        locale={post.frontmatter.i18n}
      />
    </Layout>
  )
}

StillingerPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withIntl(StillingerPage)

export const stillingerPageQuery = graphql`
  query StillingerPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        seoDescription
        seoTitle
        i18n
      }
    }
  }
`
