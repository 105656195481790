import React from 'react'
import classNames from 'classnames'
import styles from './JobView.module.sass'

export const JobView = ({
  companyLogo,
  workplace,
  jobTitle,
  callToActionUrl,
  jobSubtitle,
  className,
  deadline,
}) => {
  let metaData = workplace;
  const deadlineDefault = deadline ? deadline : 'snarest'
  if(!workplace) metaData = `Søknadsfrist: ${deadlineDefault}`
  else metaData = `${workplace}   |   Søknadsfrist: ${deadlineDefault}`
  return (
    <a
      href={callToActionUrl}
      className={classNames('column', className, styles.jobBox)}
    >
      <figure className="image">
        <img className={styles.image} src={companyLogo} alt={jobTitle} />
      </figure>
      <h3 className={styles.jobTitle}>{jobTitle}</h3>
      <p
        className={styles.meta}
      >{metaData}</p>
      <p className={styles.jobSubtitle}>{jobSubtitle}</p>
    </a>
  )
}

export default JobView
